import { cva } from "class-variance-authority";
import { ComponentPropsWithoutRef } from "react";
import { ListBoxItem as ReactAriaListBoxItem } from "react-aria-components";

import { focusRingVisibleInsetClasses } from "@sol/uikit/utils/focus";

const listBoxItemVariants = cva(["cursor-pointer truncate rounded-lg outline-none", ...focusRingVisibleInsetClasses], {
    variants: {
        variant: {
            primary: [
                "bg-purple-base text-white-base",
                "selected:bg-yellow-base selected:text-black-base",
                "pressed:bg-yellow-base pressed:text-black-base",
                "focus-visible:bg-yellow-base focus-visible:text-black-base",
                "data-[hovered]:bg-yellow-base data-[hovered]:text-black-base",
            ],
            secondary: [
                "bg-transparent-base text-black-base",
                "selected:bg-purple-base selected:text-white-base",
                "pressed:bg-purple-base pressed:text-white-base",
                "focus-visible:bg-purple-base focus-visible:text-white-base",
                "data-[hovered]:bg-purple-base data-[hovered]:text-white-base",
            ],
        },
        size: {
            small: "px-3 py-2",
            default: "px-3 py-4",
        },
    },
    defaultVariants: {
        variant: "primary",
        size: "default",
    },
});

export type Props = {
    /**
     * The color/style scheme to use
     * @default primary
     */
    variant?: "primary" | "secondary";
    /**
     * Spacing/sizing
     * @default default
     */
    size?: "small" | "default";
} & ComponentPropsWithoutRef<typeof ReactAriaListBoxItem>;

export const ListBoxItem = ({ className, ...props }: Props) => {
    return <ReactAriaListBoxItem {...props} className={listBoxItemVariants({ className, ...props })} />;
};
