import React, { Key, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useAuthenticatedUserClassrooms, useActiveClassroom } from "@sol/classrooms";
import { IClassroom } from "@sol/sdk";
import { ListBoxItem } from "@sol/uikit/collections/ListBox";
import { ComboBox } from "@sol/uikit/pickers/ComboBox";

type ClassroomSelectOption = {
    key: Key;
    label: string;
};

const mapClassroomToOption = (classroom: Pick<IClassroom, "@id" | "title">): ClassroomSelectOption => ({
    key: classroom["@id"],
    label: classroom.title,
});

const ActiveClassroomSelect = () => {
    const [t] = useTranslation();
    const { activeClassroomId, setActiveClassroom } = useActiveClassroom();

    const { classrooms } = useAuthenticatedUserClassrooms();

    const options = useMemo(() => {
        if (classrooms) {
            return classrooms.map(classroom => mapClassroomToOption(classroom));
        }
        return [];
    }, [classrooms]);

    const value = useMemo(() => {
        const activeClassroom = (classrooms || []).find(classroom => classroom["@id"] === activeClassroomId);
        return activeClassroom ? mapClassroomToOption(activeClassroom) : undefined;
    }, [activeClassroomId, classrooms]);

    return (
        <ComboBox
            aria-label={t("component.classroomsSelect.label")}
            variant="primary"
            selectedKey={value?.key ?? null}
            onSelectionChange={key => setActiveClassroom(key as string)}
            defaultItems={options}
            menuTrigger="focus"
            comboBoxInputProps={{
                className:
                    "border-2 border-white-base !bg-transparent-base text-white-base focus-within:!ring-white-base focus-within:!ring-offset-4 focus-within:!ring-offset-red-base",
            }}
            popoverProps={{ variant: "light" }}
        >
            {({ label, key }: ClassroomSelectOption) => (
                <ListBoxItem id={key} variant="secondary">
                    {label}
                </ListBoxItem>
            )}
        </ComboBox>
    );
};

export default ActiveClassroomSelect;
