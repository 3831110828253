import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

type Props = {
    text: string;
    icon: React.ReactNode;
    hasWhiteBg?: boolean;
    isNew: boolean;
    isFeminineWord?: boolean;
    className?: string;
};

// DEBT replace badly named props
const Tag = ({ isNew, icon, text, isFeminineWord = false, className }: Props) => {
    const [t] = useTranslation();

    return (
        <div className={className}>
            <span /> <span>1</span>{" "}
            <span>
                {isNew &&
                    t("component.tag.new", {
                        context: isFeminineWord ? "female" : "male",
                    })}
            </span>{" "}
            <span>{text}</span> {icon}
        </div>
    );
};

export default styled(memo(Tag))`
    ${({ hasWhiteBg, isNew, theme }) => css`
        font-weight: 600;
        font-size: 10px;
        background: ${hasWhiteBg ? theme.palette.white.base : theme.palette.grey.lightest};
        border-radius: ${({ theme }) => theme.shape.borderRadius.small};
        padding: ${theme.spacing[1]} ${theme.spacing[2]};

        & > * {
            vertical-align: middle;
        }
        & > :first-child {
            opacity: ${isNew ? "1" : "0"};
            height: 6px;
            width: 6px;
            background: ${theme.palette.red.base}
            display: ${isNew ? "inline-block" : "inline"};
            border-radius: 100%;
            margin-right: ${isNew ? theme.spacing[2] : "0"};
        }
        & > :last-child {
            width: 13px;
        }
        svg {
            path,
            line {
                stroke: ${theme.palette.grey.base};
            }
            circle {
                fill: transparent;
            }
        }
    `}
`;
