import { cva } from "class-variance-authority";
import { Button, Group, Input } from "react-aria-components";

import { Chevron } from "@sol/uikit/core/icons";
import { focusRingWithinClasses } from "@sol/uikit/utils/focus";

const groupVariant = cva(
    [
        "flex w-min min-w-[200px] max-w-[600px] rounded-lg transition-all duration-200 ease-in-out",
        ...focusRingWithinClasses,
    ],
    {
        variants: {
            variant: {
                primary: "bg-purple-base text-white-base",
                secondary: "bg-grey-lightest",
                light: "bg-white-base",
            },
        },
        compoundVariants: [
            {
                variant: ["secondary", "light"],
                className: "text-black-base",
            },
        ],
        defaultVariants: {
            variant: "primary",
        },
    },
);

const paddingVariant = cva([], {
    variants: {
        size: {
            default: "p-3",
            small: "px-2",
        },
    },
    defaultVariants: {
        size: "default",
    },
});

type Props = {
    /**
     * Whether the popover is open or not
     * @default false
     */
    isOpen?: boolean;
    /**
     * The color/style scheme to use
     * @default primary
     */
    variant?: "primary" | "secondary" | "light";
    /**
     * Spacing / sizing
     * @default default
     */
    size?: "default" | "small";
    className?: string;
    placeholder?: string;
};

export const ComboBoxInput = ({ variant = "primary", size, isOpen, placeholder, className, ...props }: Props) => {
    const strokeIconColor = variant === "primary" ? "white-base" : "purple-base";

    return (
        <Group {...props} className={groupVariant({ variant, className })}>
            <Input
                placeholder={placeholder}
                className={paddingVariant({
                    size,
                    className:
                        "w-full flex-1 border-none bg-transparent-base text-base leading-5 outline-none truncate",
                })}
            />
            <Button className={paddingVariant({ size })}>
                <Chevron fill="transparent" stroke={strokeIconColor} direction={isOpen ? "up" : "down"} />
            </Button>
        </Group>
    );
};
