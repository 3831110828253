import React, { FC } from "react";
import { useIsFetching } from "react-query";
import styled from "styled-components";

import { ScrollProvider } from "../../../contexts/ScrollContext";
import Footer from "../../Footer";
import NavBar from "../../NavBar";
import SkipLinks from "../SkipLinks";

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.palette.grey.lightest};
`;

type MainProps = {
    isFullWidth?: boolean;
    isFullHeight?: boolean;
    hideFooter?: boolean;
};

const Main = styled.main<MainProps>`
    margin: ${({ theme, isFullHeight }) => (isFullHeight ? "0 auto" : `${theme.spacing[5]} auto 0 auto`)};
    max-width: ${({ isFullWidth }) => (isFullWidth ? "100%" : "1280px")};
    width: 100%;
    min-height: calc(100vh - ${({ theme }) => theme.navbarHeight}px ${({ hideFooter }) => !hideFooter && "- 130px"});

    :has(.comments-list-container) {
        margin-top: 0;
    }
`;

type Props = {
    noFooterMargin?: boolean;
    isFullWidth?: boolean;
    isFullHeight?: boolean;
    hideFooter?: boolean;
    title?: string;
    icon?: React.ReactElement<typeof SVGSVGElement>;
};

const AuthenticatedPageLayout: FC<Props> = ({ children, noFooterMargin, isFullWidth, isFullHeight, hideFooter }) => {
    const isFetching = useIsFetching();

    return (
        <>
            <SkipLinks />
            <ScrollProvider>
                <Layout>
                    <NavBar />
                    <Main
                        role="main"
                        data-cy={isFetching ? `fetching-${isFetching}` : "loaded"}
                        isFullWidth={isFullWidth}
                        isFullHeight={isFullHeight}
                        hideFooter={hideFooter}
                        id="main-content"
                    >
                        {children}
                    </Main>
                    {!hideFooter && <Footer noFooterMargin={noFooterMargin} />}
                </Layout>
            </ScrollProvider>
        </>
    );
};

export default AuthenticatedPageLayout;
