import React, { ComponentPropsWithoutRef } from "react";
import { ComboBox as ReactAriaComboBox } from "react-aria-components";

import { ListBox } from "@sol/uikit/collections/ListBox";
import { Popover } from "@sol/uikit/overlays/Popover";

import { ComboBoxInput } from "./ComboBoxInput";

type ComboBoxVariant = "primary" | "secondary" | "light";
export type Props = {
    variant?: ComboBoxVariant;
    comboBoxInputProps?: ComponentPropsWithoutRef<typeof ComboBoxInput>;
    popoverProps?: ComponentPropsWithoutRef<typeof Popover>;
    /**
     * A render function which returns a <ListBoxItem />
     */
    children: (item: object) => React.ReactElement<any, string | React.JSXElementConstructor<any>>;
} & Omit<ComponentPropsWithoutRef<typeof ReactAriaComboBox>, "children">;

export const ComboBox = ({ variant = "primary", children, comboBoxInputProps, popoverProps, ...props }: Props) => {
    return (
        <ReactAriaComboBox {...props}>
            {({ isOpen }) => (
                <>
                    <ComboBoxInput isOpen={isOpen} variant={variant} {...comboBoxInputProps} />
                    <Popover isOpen={isOpen} className="!max-h-48 overflow-auto" variant={variant} {...popoverProps}>
                        <ListBox selectionMode="single" selectionBehavior="replace">
                            {item => {
                                const child = children(item);
                                return React.cloneElement(child, {
                                    variant: variant === "light" ? "secondary" : variant,
                                    ...child.props,
                                });
                            }}
                        </ListBox>
                    </Popover>
                </>
            )}
        </ReactAriaComboBox>
    );
};
