import { cva } from "class-variance-authority";
import { ComponentPropsWithoutRef } from "react";
import { ListBox as ReactAriaListBox } from "react-aria-components";

const listBoxVariants = cva("flex flex-col");

type Props = ComponentPropsWithoutRef<typeof ReactAriaListBox>;

export const ListBox = ({ className, ...props }: Props) => {
    return <ReactAriaListBox {...props} className={listBoxVariants({ className })} />;
};
