import { useRouter } from "next/router";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Waypoint } from "react-waypoint";
import styled from "styled-components";

import { useActiveClassroom, useAuthenticatedUserClassrooms } from "@sol/classrooms";
import { interpolate, Route } from "@sol/routing";
import { EntityTypes, getResourceUuid, INotificationResource, NotificationStatus, NotificationType } from "@sol/sdk";
import { useNotificationUpdateMutation } from "@sol/sdk/notifications/mutations/notificationUpdateMutation";
import { useNotificationListQuery } from "@sol/sdk/notifications/queries/notificationListQuery";

import useClickAway from "../../hooks/useClickAway";
import { FollowUpKind } from "../follow-ups/shared/FollowUpKind";
import { BlockSkeleton } from "../skeletons";
import SOLNotification from "../SOLNotification";
import NavBar from "./NavBar";

const BlockSkeletonStyled = styled(BlockSkeleton)`
    padding: ${({ theme }) => theme.spacing[7]};
    background: ${({ theme }) => theme.palette.white.base};
    height: 150px;
`;

function getRedirectUrlNotif(notification: INotificationResource): { href: string; as?: string } {
    const { linkEntity } = notification;
    const linkEntityUuid = getResourceUuid(linkEntity);

    if (notification.type.startsWith("group_")) {
        switch (notification.type) {
            case NotificationType.GROUP_CORRECTION_TO_LEARNER:
            case NotificationType.GROUP_WORK_TO_LEARNER:
            case NotificationType.GROUP_MESSAGE_LEARNER_TO_LEARNER:
            case NotificationType.GROUP_MESSAGE_TRAINER_TO_LEARNER:
                return {
                    href: interpolate(Route.WORKSPACE_GROUP_DETAILS, {
                        id: linkEntityUuid,
                        slug: "submit",
                    }),
                };
            default:
                // Prevent error sentry https://sentry.simplon.space/simplonprod/simplonline-v3-ui/issues/7177/?query=is%3Aunresolved%20firstSeen%3A-8w%20Cannot%20read%20property
                // Waiting for more information on this
                if (notification?.linkEntity?.["@id"]) {
                    return {
                        href: interpolate(Route.WORKSPACE_GROUP_DETAILS, {
                            id: linkEntityUuid,
                        }),
                    };
                } else {
                    return {
                        href: Route.HOME,
                    };
                }
        }
    }

    if (
        notification.type === NotificationType.SELF_EVALUATION_TO_TRAINER ||
        notification.type === NotificationType.SELF_EVALUATION_TO_TUTOR
    ) {
        return {
            href: interpolate(Route.FOLLOW_UPS_DETAILS, { id: linkEntityUuid }),
        };
    }

    if (
        notification.type === NotificationType.FOLLOW_UP_TO_TRAINER ||
        notification.type === NotificationType.FOLLOW_UP_TO_TUTOR ||
        notification.type === NotificationType.FOLLOW_UP_TO_LEARNER
    ) {
        return {
            href: interpolate(Route.FOLLOW_UPS_DETAILS, {
                followUpId: linkEntityUuid,
                kind:
                    linkEntity["@type"] === EntityTypes.SELF_EVALUATION
                        ? FollowUpKind.SELF_EVALUATION
                        : FollowUpKind.FOLLOW_UP,
            }),
        };
    }

    if (
        notification.type === NotificationType.BRIEF_COEDITOR_ADDED_TO_TRAINER ||
        notification.type === NotificationType.BRIEF_COEDITED_TO_CREATOR
    ) {
        return {
            href: interpolate(Route.BRIEFS_DETAILS, { id: linkEntityUuid }),
        };
    }

    return {
        // Prevent error sentry https://sentry.simplon.space/simplonprod/simplonline-v3-ui/issues/7152/?query=is%3Aunresolved%20firstSeen%3A-8w%20Cannot%20read%20property
        // Waiting for more information on this
        href: notification.linkEntity["@id"] ? interpolate(Route.TOPICS_DETAILS, { id: linkEntityUuid }) : Route.HOME,
    };
}

const PER_PAGE = 5;

const NavBarContainer = () => {
    const [displayedNotifs, setDisplayedNotifs] = useState<INotificationResource[]>([]);
    const [numberPageMax, setNumberPageMax] = useState(0);
    const { classrooms = [] } = useAuthenticatedUserClassrooms();
    const { setActiveClassroom } = useActiveClassroom();
    const [page, setPage] = useState(1);
    const hasMore = page < numberPageMax;
    const navbarRightRef = useRef<HTMLUListElement>(null);
    const router = useRouter();

    const [updateNotificationMutation, { isLoading: updateNotificationMutationLoading }] =
        useNotificationUpdateMutation();

    const [showNotifList, setShowNotifList] = useClickAway(navbarRightRef);

    const { data: notifications, refetch: refetchNotifications } = useNotificationListQuery({
        pagination: { page, perPage: PER_PAGE },
    });

    const { data: newNotificationsCounter, refetch: refetchNotifsCount } = useNotificationListQuery({
        filters: { status: NotificationStatus.NEW },
        pagination: { page: 1, perPage: 0 },
    });

    const { refetch: refetchAllNotifs } = useNotificationListQuery(
        {},
        {
            enabled: false,
            onSuccess: notifications => removeAllNotif(notifications["hydra:member"]),
        },
    );

    const handleLoadMore = useCallback(() => {
        if (hasMore) {
            setPage(page + 1);
            refetchNotifications();
        }
    }, [hasMore, page]);

    useEffect(() => {
        if (!notifications) {
            refetchNotifications();
        } else {
            const newNotifications = notifications["hydra:member"];
            setDisplayedNotifs(prevNotifications => {
                const uniqueNotifications = new Map(
                    prevNotifications.map(notification => [notification["@id"], notification]),
                );
                newNotifications.forEach(notification => {
                    uniqueNotifications.set(notification["@id"], notification);
                });
                return Array.from(uniqueNotifications.values());
            });

            if (!numberPageMax) {
                const totalNotifs = notifications["hydra:totalItems"];
                setNumberPageMax(Math.ceil(totalNotifs / PER_PAGE));
            }
        }
    }, [notifications, numberPageMax]);

    const closePanel = () => setShowNotifList(false);

    const updateStatusNotifRequest = useCallback(
        async (notification, status) => {
            if (notification.status === status) {
                return notification;
            }

            const data = await updateNotificationMutation({
                notification,
                status,
            });

            if (data) {
                refetchNotifsCount();
            }

            return data;
        },
        [updateNotificationMutation],
    );

    const updateArrAllNotifs = useCallback(
        notifUpdated => {
            return [...displayedNotifs.filter(item => item["@id"] !== notifUpdated["@id"]), notifUpdated].filter(
                item => item.status !== NotificationStatus.HIDDEN,
            );
        },
        [displayedNotifs],
    );

    const handleClickNotif = useCallback(
        async (notification: INotificationResource) => {
            const notif = await updateStatusNotifRequest(notification, NotificationStatus.READ);
            const classroom = classrooms.find(cu => cu["@id"] === notif?.recipientClassroom);
            if (classroom) {
                setActiveClassroom(classroom["@id"]);
            }
            const updatedNotifications = updateArrAllNotifs(notif);
            setDisplayedNotifs(updatedNotifications);
            const { href, as } = getRedirectUrlNotif(notification);
            await router.push(href, as);
            closePanel();
        },
        [updateStatusNotifRequest, updateArrAllNotifs, setDisplayedNotifs, closePanel],
    );

    const updateAllNotifs = useCallback(
        (
            status: NotificationStatus,
            notifs: INotificationResource[],
            conditionFn?: (v: INotificationResource) => boolean,
        ) =>
            Promise.all(
                notifs.map(async notif => {
                    if (conditionFn && conditionFn(notif)) {
                        return Promise.resolve(notif);
                    }
                    return await updateStatusNotifRequest(notif, status);
                }),
            ),
        [displayedNotifs, updateStatusNotifRequest],
    );

    const removeOneNotif = useCallback(
        async notification => {
            const updatedNotification = await updateStatusNotifRequest(notification, NotificationStatus.HIDDEN);
            const displayedNotificationsUpdated = updateArrAllNotifs(updatedNotification);

            setDisplayedNotifs(displayedNotificationsUpdated);
        },
        [updateStatusNotifRequest, updateArrAllNotifs, setDisplayedNotifs],
    );

    // Loader display is based on isPending
    // but isPending is too short compared to deletion time
    const removeAllNotif = useCallback(
        async (allNotifs: INotificationResource[]) => {
            await updateAllNotifs(NotificationStatus.HIDDEN, allNotifs);
            setDisplayedNotifs([]);
            closePanel();
            refetchNotifsCount();
        },
        [updateArrAllNotifs, setDisplayedNotifs, closePanel, refetchNotifsCount],
    );

    const transformNewToSeen = useCallback(async () => {
        const updatedNotifs = await updateAllNotifs(
            NotificationStatus.SEEN,
            displayedNotifs,
            item => item.status !== NotificationStatus.NEW,
        );

        setDisplayedNotifs(updatedNotifs);
    }, [updateAllNotifs, setDisplayedNotifs]);

    return (
        <NavBar
            counterNotification={newNotificationsCounter ? newNotificationsCounter?.["hydra:totalItems"] : 0}
            showNotifList={showNotifList}
            toggleShowNotifList={() => setShowNotifList(!showNotifList)}
            ref={navbarRightRef}
            listNotification={
                <SOLNotification
                    allNotifs={displayedNotifs}
                    removeOneNotif={removeOneNotif}
                    showLoader={updateNotificationMutationLoading}
                    removeAllNotif={refetchAllNotifs}
                    handleClickNotif={handleClickNotif}
                    transformNewToSeen={transformNewToSeen}
                    infiniteScroll={children => (
                        <>
                            {children}
                            {hasMore && <BlockSkeletonStyled />}
                            <Waypoint
                                bottomOffset="-160px"
                                onEnter={() => {
                                    handleLoadMore();
                                }}
                            />
                        </>
                    )}
                />
            }
        />
    );
};

export default memo(NavBarContainer);
