/* eslint-disable react/display-name */
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "src/uikit";
import styled, { css } from "styled-components";

import { Feature, useCheckFeatureFlag } from "@sol/features";
import { Admin, Bell, Clipboard, Logo, Profile, SelfEvaluation, SvgIcon, Work } from "@sol/icons";
import { Route } from "@sol/routing";

import SROnlyLabel from "../../uikit/SROnly";
import ActiveClassroomSelect from "../ActiveClassroomSelect";
import { useBriefsViews } from "../briefs/BriefListPage";

type WrapperItemProps = {
    "aria-current"?: boolean;
    filled?: boolean;
};

const ItemLabel = styled(Text)`
    color: ${({ theme }) => theme.palette.white.base};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30ch;
`;

const Counter = styled.div<{ isSelected: boolean }>`
    ${({ isSelected, theme }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        margin: 0 auto;
        top: -${theme.spacing[2]};
        left: ${theme.spacing[4]};
        right: 0;
        height: 12px;
        min-width: 12px;
        width: fit-content;
        padding: 0 ${({ theme }) => theme.spacing[2]};
        background: ${theme.palette[isSelected ? "red" : "white"].base};
        color: ${theme.palette[isSelected ? "white" : "red"].base};
        border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        font-size: 10px;
    `}
`;

const WrapperItem = styled.a<WrapperItemProps>`
    ${({ theme, "aria-current": isSelected }) => {
        const primaryColor = theme.palette[isSelected ? "red" : "white"].base;
        const secondaryColor = theme.palette[isSelected ? "white" : "red"].base;

        return css`
            padding: ${theme.spacing[3]} ${theme.spacing[4]};
            background: ${isSelected ? theme.palette.white.base : "transparent"};
            border: 4px solid transparent;
            border-radius: 0 0 ${theme.shape.borderRadius.medium} ${theme.shape.borderRadius.medium};
            height: 93%;
            align-self: flex-start;
            cursor: pointer;
            transition: all 0.2s;
            outline: 0;

            --icon-color: ${primaryColor};

            ${ItemLabel} {
                color: ${primaryColor};
            }

            &:hover,
            &:active {
                background: ${primaryColor};
                --icon-color: ${secondaryColor};

                ${ItemLabel} {
                    color: ${secondaryColor};
                }

                ${Counter} {
                    background-color: ${secondaryColor};
                    color: ${primaryColor};
                }
            }

            &:focus {
                background: ${secondaryColor};
                --icon-color: ${primaryColor};
                border: 4px solid ${isSelected ? theme.palette.white.base : theme.palette.grey.lightest};
                box-shadow: ${isSelected ? `inset 0 0 0 3px ${theme.palette.red.base}` : null};

                ${ItemLabel} {
                    color: ${primaryColor};
                }

                ${Counter} {
                    background-color: ${primaryColor};
                    color: ${secondaryColor};
                }
            }
        `;
    }}
`;

const Left = styled.ul`
    flex: 1;
    display: flex;
    align-items: center;
    height: 100%;

    & > :first-child {
        margin: 0;

        ${SvgIcon} {
            width: 40px;
            height: 40px;
        }
    }

    ${WrapperItem} + ${WrapperItem} {
        margin-left: ${({ theme }) => theme.spacing[4]};
    }
`;

const ContainerFullHeight = styled.nav`
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
`;

const IconText = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 42px;

    &:hover {
        cursor: pointer;
    }
`;

const Right = styled.ul`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    position: relative;

    ${WrapperItem} + ${WrapperItem} {
        margin-left: ${({ theme }) => theme.spacing[4]};
    }
`;

const SelectContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 320px;
`;

type OptionsNav = {
    url: string;
    icon: React.ReactNode;
    text: string;
    active?: (url: string) => boolean;
};

type Props = {
    counterNotification: number;
    listNotification: React.ReactNode;
    showNotifList: boolean;
    toggleShowNotifList: () => void;
    className?: string;
};

const NavBar = React.forwardRef<HTMLUListElement, Props>(
    ({ counterNotification, listNotification, showNotifList, toggleShowNotifList, className }, navbarRightRef) => {
        const [t] = useTranslation();
        const { pathname, asPath } = useRouter();
        const checkFeatureFlag = useCheckFeatureFlag();
        const briefViews = useBriefsViews();

        const optionsNav = useMemo((): OptionsNav[] => {
            const options = [];

            if (briefViews.length > 0) {
                options.push({
                    url: briefViews[0].id,
                    icon: <Clipboard />,
                    text: t("component.navbar.briefs"),
                    active: (url: string) => url.includes(Route.BRIEFS),
                });
            }

            if (checkFeatureFlag([Feature.TOPICS, Feature.WORKSPACE_GROUP])) {
                options.push({
                    url: Route.WORKSPACES,
                    icon: <Work />,
                    text: t("component.navbar.corrections"),
                });
            }

            if (checkFeatureFlag(Feature.FOLLOW_UPS)) {
                options.push({
                    url: Route.FOLLOW_UPS_LIST,
                    icon: <SelfEvaluation />,
                    text: t("component.navbar.followUp"),
                });
            }

            if (checkFeatureFlag(Feature.ADMIN)) {
                options.push({
                    url: Route.ADMIN,
                    icon: <Admin />,
                    text: t("component.navbar.admin"),
                });
            }

            return options;
        }, [checkFeatureFlag, t, briefViews]);

        return (
            <header role="banner" className={className} data-cy="navbar">
                <ContainerFullHeight role="navigation">
                    <Left>
                        <li className="inline-flex">
                            <Link href={Route.HOME} passHref>
                                <WrapperItem aria-current={pathname === Route.HOME} filled>
                                    <IconText>
                                        <Logo />
                                        <SROnlyLabel>{t("navbar.goHomepage")}</SROnlyLabel>
                                    </IconText>
                                </WrapperItem>
                            </Link>
                        </li>
                        {optionsNav.map(({ url, icon, text, active }) => (
                            <li className="inline-flex" key={url}>
                                <Link href={url} passHref>
                                    <WrapperItem
                                        aria-current={active ? active(asPath) : pathname.includes(url)}
                                        data-cy="navbar-link"
                                    >
                                        <IconText>
                                            {icon}
                                            <ItemLabel variant="subheading" as="span">
                                                {text}
                                            </ItemLabel>
                                        </IconText>
                                    </WrapperItem>
                                </Link>
                            </li>
                        ))}
                    </Left>
                    {checkFeatureFlag(Feature.SELECT_ACTIVE_CLASSROOM) && (
                        <SelectContainer data-cy="select-active-classroom">
                            <ActiveClassroomSelect />
                        </SelectContainer>
                    )}
                    <Right ref={navbarRightRef}>
                        <li className="inline-flex">
                            <Link href={Route.PROFILE} passHref>
                                <WrapperItem aria-current={pathname.includes(Route.PROFILE)} data-cy="navbar-link">
                                    <IconText>
                                        <Profile />
                                        <ItemLabel variant="subheading" as="span">
                                            {t("component.navbar.profile")}
                                        </ItemLabel>
                                    </IconText>
                                </WrapperItem>
                            </Link>
                        </li>
                        {checkFeatureFlag(Feature.NOTIFICATIONS) && (
                            <li className="inline-flex">
                                <WrapperItem
                                    as="button"
                                    aria-current={showNotifList}
                                    onClick={toggleShowNotifList}
                                    data-cy="notification-button"
                                    aria-expanded={showNotifList}
                                >
                                    <IconText>
                                        <Bell />
                                        {counterNotification > 0 && (
                                            <Counter isSelected={showNotifList}>{counterNotification}</Counter>
                                        )}
                                        <ItemLabel variant="subheading" as="span">
                                            {t("navbar.notifs")}
                                        </ItemLabel>
                                    </IconText>
                                </WrapperItem>
                                {showNotifList && listNotification}
                            </li>
                        )}
                    </Right>
                </ContainerFullHeight>
            </header>
        );
    },
);

export default styled(NavBar)`
    height: ${({ theme }) => theme.navbarHeight}px;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: ${({ theme }) => theme.zIndex.overlay};
    padding: 0 37px 0 ${({ theme }) => theme.spacing[7]};
    background-color: ${({ theme }) => theme.palette.red.base};
    ${({ theme }) => theme.typography.subheading}

    a {
        text-decoration: none;
    }
`;
