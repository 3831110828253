import React from "react";
import { Text } from "src/uikit";
import styled, { css } from "styled-components";

import { Cross } from "@sol/icons";
import { NotificationStatus, NotificationType } from "@sol/sdk";
import { IconButton } from "@sol/uikit/buttons";

import Tag from "../../Tag";
import { NotificationDataProps } from "./SOLNotificationItemContainer";

// DEBT -> Following component should be "a" but because href management is fucked up we are forced to use a clickable div 💩
const Content = styled.a`
    display: flex;
    padding: 0 0 ${({ theme }) => theme.spacing[6]} 0;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.lightest};
    text-align: left;
    border: 0;
    background-color: transparent;

    cursor: pointer;
`;

const TagTime = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing[4]};
    --text-color: ${({ theme }) => theme.palette.grey.base};
`;

type Props = {
    nameToDisplay: string;
    notifData: NotificationDataProps;
    date: string;
    deleteNotif: (e: React.MouseEvent<HTMLButtonElement>) => void;
    clickNotif: () => void;
    avatar: React.ReactNode;
    status: NotificationStatus;
    className?: string;
};

// DEBT rework markup
const SOLNotificationItem = ({
    deleteNotif,
    clickNotif,
    notifData,
    nameToDisplay,
    date,
    avatar,
    status,
    className,
}: Props) => {
    const isNew = status === NotificationStatus.NEW;
    const isSeenOrNew = [NotificationStatus.NEW, NotificationStatus.SEEN].includes(status);

    return (
        <div className={className} data-cy="notification-item">
            <IconButton variant={isSeenOrNew ? "secondary" : "primary"} onClick={deleteNotif}>
                <Cross />
            </IconButton>
            <Content onClick={clickNotif}>
                {avatar}
                <div>
                    <Text variant="label">
                        {nameToDisplay} {notifData.title}
                    </Text>
                    <TagTime>
                        <Tag
                            hasWhiteBg={isNew}
                            isNew={isNew}
                            icon={notifData.icon}
                            text={notifData.text}
                            isFeminineWord={notifData.type === NotificationType.WORK_CORRECTED}
                        />
                        <Text as="span" variant="label-xs">
                            {date}
                        </Text>
                    </TagTime>
                </div>
            </Content>
        </div>
    );
};

export default styled(SOLNotificationItem)`
    ${({ status, theme }) => {
        const isSeenOrNew = [NotificationStatus.NEW, NotificationStatus.SEEN].includes(status);
        const backgroundColor = isSeenOrNew ? theme.palette.purple.lightest : theme.palette.white.base;

        return css`
            position: relative;
            border-radius: ${theme.shape.borderRadius.medium};
            padding: ${theme.spacing[6]} ${theme.spacing[4]} 0;
            background: ${backgroundColor};

            ${IconButton} {
                width: 24px;
                height: 24px;
                position: absolute;
                right: ${theme.spacing[3]};
                top: ${theme.spacing[2]};
                --icon-color: ${theme.palette.black.base};
            }
        `;
    }}
`;
