import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "src/uikit";
import styled from "styled-components";

import { useAuthenticatedUser } from "@sol/authentication";
import { useActiveClassroom } from "@sol/classrooms";
import { Loader } from "@sol/icons";
import { useCurrentBrand } from "@sol/sdk/brands/useCurrentBrand";
import Button from "@sol/uikit/buttons/Button";

import useClickAway from "../hooks/useClickAway";
import { getRoleTranslationKey } from "../utils/getRole";

const Container = styled.div`
    position: relative;
`;

const MenuList = styled.ul`
    position: absolute;
    bottom: 60px;
    z-index: ${({ theme }) => theme.zIndex.navigation};
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    background-color: ${({ theme }) => theme.palette.white.base};
    box-shadow: 4px 4px 0px ${({ theme }) => theme.palette.yellow.base};
    overflow: hidden;
`;

export const ListItem = styled.li`
    list-style: none;

    &:hover {
        background-color: ${({ theme }) => theme.palette.yellow.base};
    }
`;

const Label = styled(Text)`
    padding: ${({ theme }) => `${theme.spacing[3]} ${theme.spacing[5]}`};
    margin: 0;
    ${({ theme }) => theme.typography.label}
    background-color: transparent;
    border: 0;
    cursor: pointer;
    width: 100%;
    text-align: left;
`;

const SupportButton = () => {
    const [t] = useTranslation();
    const { data: currentBrand, isLoading: isCurrentBrandLoading } = useCurrentBrand({});
    const supportEmail = currentBrand?.brand?.contactEmail;
    const {
        user: { name, surname, email, roles, language },
    } = useAuthenticatedUser();

    const { activeClassroom } = useActiveClassroom();
    const menuRef = useRef<HTMLDivElement>(null);
    const [showMenu, setShowMenu] = useClickAway(menuRef);
    const [isCopied, setIsCopied] = useState(false);

    const mailTemplate = useMemo(
        () =>
            `mailto:${supportEmail}?subject=${t("component.footer.support.helpRequest")} - ${name} ${surname} - ${
                activeClassroom?.title
            }&body=%5B${t("component.footer.support.describeYourIssue")}%5D%0D%0A%0D%0A%0D%0A%0D%0A__________%0D%0A${t(
                "component.footer.support.informations",
            )}%0D%0A%0D%0Aname%3A%20${name}%20${surname}%0D%0Aemail%3A%20${email}%0D%0Arole%3A%20${t(
                getRoleTranslationKey(roles),
            )}%0D%0Alanguage%3A%20${language.title}%0D%0Aclassroom%3A%20${activeClassroom?.title}%0D%0Alocation%3A%20${
                activeClassroom?.location?.title
            }%0D%0Areference%3A%20${activeClassroom?.externalReferences?.[0]}`,
        [],
    );

    const copyUrl = useCallback(() => {
        if (supportEmail) {
            // create temporary input element
            const el = document.createElement("input");
            el.value = supportEmail;
            el.id = "copy-input";
            document.body.appendChild(el);

            // copy input value
            el.select();
            document.execCommand("copy");

            // remove the input from the DOM and set back body's width to 100vw
            document.body.removeChild(el);
            document.body.style.width = "100vw";
        }
    }, [supportEmail]);

    const handleCopyEmail = useCallback(() => {
        copyUrl();

        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1500);
    }, [copyUrl, setIsCopied]);

    return (
        <Container ref={menuRef}>
            <Button
                disabled={isCurrentBrandLoading}
                type="button"
                aria-expanded={showMenu}
                variant="primary"
                outlined
                onClick={() => setShowMenu(!showMenu)}
            >
                {isCurrentBrandLoading ? <Loader /> : supportEmail}
            </Button>
            {showMenu && (
                <MenuList>
                    <ListItem
                        onClick={() => {
                            window.open(mailTemplate);
                            setShowMenu(false);
                        }}
                    >
                        <Label as="button" type="button">
                            {t("component.footer.supportButton.openEmailClient")}
                        </Label>
                    </ListItem>
                    <ListItem onClick={handleCopyEmail} onBlur={() => setShowMenu(false)}>
                        <Label as="button" type="button">
                            {isCopied
                                ? t("component.footer.supportButton.copied")
                                : t("component.footer.supportButton.copyEmailAddress")}
                        </Label>
                    </ListItem>
                </MenuList>
            )}
        </Container>
    );
};

export default SupportButton;
