import Link from "next/link";
import React from "react";
import { Text } from "src/uikit";
import styled from "styled-components";

import { IPage, usePageListQuery } from "@sol/sdk";
import { PageStatus } from "@sol/sdk/pages/IPageResource";

import SupportButton from "./SupportButton";

const LinkContent = styled(Text)`
    color: ${({ theme }) => theme.palette.purple.base};
`;

const List = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[7]}`};
    border-bottom: 2px solid ${({ theme }) => theme.palette.red.base};
    background-color: ${({ theme }) => theme.palette.white.base};
    list-style-type: none;
`;

type Props = {
    noFooterMargin?: boolean;
    className?: string;
};

const Footer = ({ className }: Props) => {
    const { data: pages } = usePageListQuery({ filters: { status: PageStatus.PUBLISHED } });

    return (
        <footer className={className} role="contentinfo">
            <List>
                {pages &&
                    pages["hydra:member"].map((page: IPage) => (
                        <li key={page["@id"]}>
                            <Link href={page["@id"]} passHref>
                                <LinkContent variant="label" as="a">
                                    {page.title}
                                </LinkContent>
                            </Link>
                        </li>
                    ))}
                <li>
                    <SupportButton />
                </li>
            </List>
        </footer>
    );
};

export default styled(Footer)`
    ${List} {
        margin: ${({ noFooterMargin, theme }) => (noFooterMargin ? 0 : `${theme.spacing[7]} 0 0 0`)};
    }
`;
