import React, { PureComponent, ReactNode } from "react";
import { useTranslation, WithTranslation, withTranslation } from "react-i18next";
import { Text } from "src/uikit";
import styled from "styled-components";

import { Loader, TrashCan } from "@sol/icons";
import { INotificationResource } from "@sol/sdk";
import { GhostButton } from "@sol/uikit";

import SOLNotificationItemContainer from "./SOLNotificationItem";
import SOLNotificationItem from "./SOLNotificationItem/SOLNotificationItem";

const NOTIFICATIONS_CONTAINER_MAX_HEIGHT = "596px";

type NoNotificationProps = {
    className?: string;
};

const NoNotification = styled(({ className }: NoNotificationProps) => {
    const [t] = useTranslation();

    return (
        <div className={className}>
            <Text variant="label">{t("component.navbar.noNotifications")}</Text>
        </div>
    );
})`
    background: url("/static/hero-bg.svg");
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NotificationsList = styled.ul`
    height: ${NOTIFICATIONS_CONTAINER_MAX_HEIGHT};
    padding: 0;
    margin: 0;
    overflow-y: auto;
`;

const NotificationsPanelFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: ${({ theme }) => `0 0 ${theme.shape.borderRadius.medium} ${theme.shape.borderRadius.medium}`};
    flex: 1;
    text-transform: uppercase;
    padding: ${({ theme }) => theme.spacing[3]} ${({ theme }) => theme.spacing[4]} 0 ${({ theme }) => theme.spacing[4]};
    border-top: 1px solid ${({ theme }) => theme.palette.grey.lightest};
`;

type Props = {
    allNotifs: INotificationResource[];
    infiniteScroll: (v: ReactNode[]) => ReactNode;
    handleClickNotif: (v: INotificationResource) => void;
    removeOneNotif: (v: INotificationResource) => void;
    removeAllNotif: () => void;
    showLoader: boolean;
    transformNewToSeen: () => void;
    className?: string;
} & WithTranslation;

type State = {
    customIsPending: boolean;
    allNotifs: INotificationResource[];
};

class SOLNotification extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            customIsPending: false,
            allNotifs: props.allNotifs,
        };
    }

    componentDidUpdate = (prevProps: Props, prevState: State) => {
        if (prevState.allNotifs.length !== this.props.allNotifs.length) {
            this.setState(prevState => ({
                ...prevState,
                allNotifs: this.props.allNotifs,
            }));
        }
    };

    componentWillUnmount = () => {
        this.props.transformNewToSeen();
    };

    render() {
        const { infiniteScroll, handleClickNotif, removeOneNotif, showLoader, removeAllNotif, t, className } =
            this.props;
        const { allNotifs } = this.state;

        if (allNotifs.length === 0) {
            return (
                <div className={className}>
                    <NoNotification />
                </div>
            );
        }

        return (
            <div className={className}>
                <NotificationsList>
                    {infiniteScroll(
                        allNotifs.map(notif => (
                            <li key={notif["@id"]}>
                                <SOLNotificationItemContainer
                                    handleClickNotif={handleClickNotif}
                                    removeOneNotif={removeOneNotif}
                                    notif={notif}
                                />
                            </li>
                        )),
                    )}
                </NotificationsList>
                <NotificationsPanelFooter>
                    {!showLoader ? (
                        <GhostButton variant="danger" onClick={removeAllNotif}>
                            {t("navbar.deleteAll").toUpperCase()}
                            <TrashCan />
                        </GhostButton>
                    ) : (
                        <Loader />
                    )}
                </NotificationsPanelFooter>
            </div>
        );
    }
}

export default styled(withTranslation()(SOLNotification))`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: -${({ theme }) => theme.spacing[6]};

    max-height: ${NOTIFICATIONS_CONTAINER_MAX_HEIGHT};
    width: 360px;

    border: 2px solid ${({ theme }) => theme.palette.purple.lighter};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    padding: ${({ theme }) => theme.spacing[3]};
    background-color: ${({ theme }) => theme.palette.white.base};

    ${SOLNotificationItem} + ${SOLNotificationItem} {
        margin-bottom: ${({ theme }) => theme.spacing[2]};
    }
`;
